import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import App from '../App';
import About from '../About/About'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={App}></Route>
      <Route exact path="/about" component={About}></Route>
      <Route path="*" component={App}></Route>
    </Switch>
  </BrowserRouter>
)

export default Routes