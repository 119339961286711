import React, { useEffect } from "react";
import "./About.css";

const About = () => {
  useEffect(() => {
    document.body.style.overflowY = "scroll";
  });
  return (
    <div className="container">
      <div className="about-header">
        <div className="header-text center">Number Capital</div>
      </div>
      <div>
        <div className="sub-title">
          <div className="sub-title-header green-text">WHAT WE DO</div>
          <div className="quote">We help innovative students to build great companies.</div>
        </div>
        <div className="text">
          We help Indonesian students to turn their innovative ideas and
          research projects into real solutions and impacts that can be measured
          in NUMBER. Our goal is to make sure that you can succeed even without
          prior startup or business experiences by supporting you with seed
          capital, incorporation, development team, go-to-market execution all
          the way to scaling and monetisation, and more fundraising if needed.
          We strongly believe in the role of innovations and research projects
          in advancing Indonesia. Many legendary companies were created by
          students from universities and laboratories like you, so we want to
          support more students to build more companies.
          <br />
          <br />
          Facebook 3Billion+ monthly active users were started out as a social
          network idea in Harvard. Google 4Billion+monthly active users were
          started out as a backlink algorithm project in Stanford laboratory.
          Dropbox 500Million+ monthly active users were started out as a file
          syncing idea in MIT. Wardah 50Million+ cosmetic users were started out
          as a house cosmetic project by ITB School of Pharmacy student. Payfazz
          10Million+ banking users were started out as a kost app project by ITB
          and Binus students. We strongly believe in the thesis that students,
          innovations, and research projects are core for ideas and NUMBER to
          build great companies.
        </div>
      </div>
      <div>
        <div className="sub-title">
          <div className="sub-title-header green-text">
            WHY YOU SHOULD APPLY
          </div>
          <div className="quote">Your ideas can be the next Facebook or Google by applying.</div>
        </div>
        <div className="text">
          Simply by applying and filling a 10 minutes form,{" "}
          <span> NUMBER </span>will help evaluate your ideas and support it with
          capital if it passes the evaluation. You don’t need prior startup or
          business experiences and capital to make your ideas happen, we will
          support you with our resources. We are confident that we can help turn
          your ideas into something big because we have done it many times for
          other students before.
        </div>
      </div>
      <div className="contact-section">
        <div className="sub-title-header contact-header">Contact</div>
        <div className="text contact-detail">
          Menara Prima 12th Floor |{" "}
          <a href="mailto: asknumbervc@gmail.com" className="email">
            asknumbervc@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
