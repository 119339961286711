import React, { useState, useEffect } from 'react';
import './App.css';
import Slider from "react-slick";
import payfazz from './images/payfazz.svg';
import shipper from './images/shipper.svg';
import pahamify from './images/pahamify01.svg';
import verihubs from './images/verihubs.png';
import arrow from './images/arrow.png';

function App() {
  const [slider, setSlider] = useState(null);

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    verticalSwiping: true,
    vertical: true,
    arrows: false,
    pauseOnHover:false,
    autoplay: true,
    autoplaySpeed: 7000,
  };


  const slide = (y) => {
    y > 0 ? (
       slider.slickNext()
    ) : (
       slider.slickPrev()
    )
  }
  window.onresize = () => { reportWindowSize() }
  var supportsOrientationChange = "onorientationchange" in window,
    orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";
  window.addEventListener(orientationEvent, function() {
    reportWindowSize();
  }, false);
  function reportWindowSize() {
    setHeight();
  }

  const setHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  var throttle = 1300;
  var time = -1;

  const checkWheel = (e) => {
    if ( slider === null  ) return;

    var now = Date.now();
    if (time !== -1 && now - time < throttle && navigator.appVersion.indexOf("Mac")!=-1)
     return; // Get out, we haven't waited long enough, only applies to Mac

    time = now;
    var e_delta = (e.deltaY || -e.wheelDelta || e.detail);
    var delta =  e_delta && ((e_delta >> 10) || 1) || 0;

    slide(delta);
  }

  useEffect(() => {
    // Then we set the value in the --vh custom property to the root of the document
    setHeight();
    let temp = document.getElementsByClassName('myslider')[0];
    temp.addEventListener('wheel', checkWheel);
  }, [checkWheel])

  const [isClicked, setIsClicked] = useState(false);
  const arrowClicked = () => {
    setIsClicked(true);
    slider.slickNext();
  }

  return (
    <Slider {...settings} className="myslider" ref={c => (setSlider(c))}>
      <section id="section01">
        <div className="section-content-container">
          <div className="section-body"><h3>Hendra, Jefri and Ricky enable financial access for SEA’s unbanked</h3></div>
          <div className="section-footer row justify-content-between">
            <div className="left-footer">
              <img className="company-logo" src={payfazz} alt="payfazz-logo"/>
            </div>
            <div className="right-footer">
              <div className="learn-more">
                <a href="https://techcrunch.com/2020/07/06/payfazz-gets-53-million-to-give-more-indonesians-access-to-financial-services/" target="_blank">Learn More</a>
                <div className="ellipse">
                </div>
              </div>
              <div className="number-logo-text">
                N01
              </div>
            </div>
          </div>
          <div className="arrowDiv" style={{display: isClicked ? 'none' : 'block'}}>
            <img onClick={arrowClicked} className="arrow" src={arrow} alt="arrow"/>
          </div>
        </div>
      </section>
      <section id="section02">
        <div className="section-content-container">
          <div className="section-body"><h3>Budi and Phil build logistics network for Indonesian online shops</h3></div>
          <div className="section-footer row justify-content-between">
            <div className="left-footer">
              <img className="company-logo" src={shipper} alt="shipper-logo" />
            </div>
            <div className="right-footer">
              <div className="learn-more">
                <a href="https://e27.co/indonesias-logistics-aggregator-shipper-secures-us20m-series-a-led-by-naspers-20200520/" target="_blank">Learn More</a>
                <div className="ellipse">
                </div>
              </div>
              <div className="number-logo-text">
                N02
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section03">
        <div className="section-content-container">
          <div className="section-body"><h3>Edria, Fikri and Ikhsan help Indonesian students maximize their potential</h3></div>
          <div className="section-footer row justify-content-between">
            <div className="left-footer">
              <img className="company-logo" src={pahamify} alt="pahamify-logo" />
            </div>
            <div className="right-footer">
            <div className="learn-more">
                <a href="https://kr-asia.com/indonesian-education-tech-company-pahamify-bags-usd-150000-investment-from-y-combinator" target="_blank">Learn More</a>
                <div className="ellipse">
                </div>
              </div>
              <div className="number-logo-text">
                N03
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section04">
        <div className="section-content-container">
          <div className="section-body"><h3>Williem builds verification and authentication AI for Indonesian companies</h3></div>
          <div className="section-footer row justify-content-between">
            <div className="left-footer">
              <img className="company-logo" src={verihubs} alt="verihubs-logo"/>
            </div>
            <div className="right-footer">
            <div className="learn-more">
                <a href="https://makassardigitalvalley.id/inilah-18-startup-yang-terpilih-dalam-program-indigo-batch-i-tahun-2020/" target="_blank">Learn More</a>
                <div className="ellipse">
                </div>
              </div>
              <div className="number-logo-text">
                N04
              </div>
            </div>
          </div>
        </div>
      </section>
    </Slider>
  );
}

export default App;
