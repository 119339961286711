import React from 'react'
import logo from './images/logo.svg'
import './NavigationBar.css'
const redirect = () => {
  window.open('https://forms.gle/xNviV7ymEyEJhsEH6');
}

const NavigationBar = () => (
  <header className="header-navbar">
    <div className="logo-wrapper">
      <a href="/">
        <img src={logo} alt="Logo"></img>
      </a>
    </div>
    <div className="button-wrapper">
      <div className="about-button"><a href="/about">About</a></div>
      <button onClick={redirect}>Apply</button>
    </div>
  </header>
)

export default NavigationBar;